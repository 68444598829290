import ApiInstance from './ApiInstance.js'

export default {
  
  getGenders() {
    return ApiInstance({ requiresAuth: true }).get('/gender');
  },
  addNewGender(Data) {
    return ApiInstance({ requiresAuth: true }).post('/gender', Data);
  },
  editGender(Data, id)
  {
    return ApiInstance({ requiresAuth: true }).put(`/gender/${id}`, Data);
  },
  deleteGender(id)
  {
    return ApiInstance({ requiresAuth: true }).delete(`/gender/${id}`);
  }
}
