<template>
  <div data-app>
    <div class="row mx-0 pt-3">
      <div class="col-md-7 pl-0 row mx-0">
        <div class="col-6 py-4 px-4 border_b_silver bg-white">
          <span class="card-label font-weight-bolder Main_Blue">Gender </span>
        </div>
        <div class="col-6 p-0 pt-3 text-end border_b_silver bg-white">
          <button
            type="button"
            class="custom-add-new-record-button btn_dash_ch mr-2"
            fab
            small
            @click="createFormEnable4"
          >
            <span class="v-btn__content" style="font-size: 14px !important"
              >Add new</span
            >
          </button>
        </div>
        <v-data-table
          :headers="headers4"
          :items="data4"
          item-key="name"
          class="elevation-1 px-4 rounded-0 headers_job_function_ch"
        >
          <template v-slot:item="props">
            <tr class="border__ch">
              <td
                class="Main_Blue"
                style="font-size: 14px; font-weight: 500 !important"
              >
                {{ props.item.gender_name }}
              </td>
              <td>
                <toggle-button
                  :value="props.item.status == '' + 1 ? true : false"
                  color="#82C7EB"
                  :sync="true"
                  :labels="false"
                  @change="changeGanderStatus(props.item)"
                />
                {{ props.item.status ? "Activated" : "Deactivated" }}
              </td>
              <td class="border-0" style="">
                <v-btn
                  class="py-2 ch_btn_silver_rounded_0"
                  fab
                  small
                  @click="editButtonClick4(props.item)"
                >
                  <img
                    src="\images/job_application/Edit_Icon.svg"
                    alt=""
                    width="16"
                    height="16"
                  />
                </v-btn>
                <v-btn
                  class="py-2 ch_btn_silver_rounded_0"
                  fab
                  small
                  @click="askUserAboutDelete(props.item)"
                >
                  <img
                    src="\images/job_application/Delete.svg"
                    alt=""
                    width="16"
                    height="16"
                  />
                </v-btn>
              </td>
            </tr>
            <tr>
              <td colspan="12" style="height: 12px"></td>
            </tr>
          </template>
        </v-data-table>
      </div>
      <div class="col-md-5 pl-0 pt-0">
        <!-- create form start -->
        <div class="col-12 py-4 px-4 border_b_silver bg-white">
          <span
            v-if="createForm4"
            class="card-label font-weight-bolder Main_Blue"
            >Add new Gender
          </span>
          <span
            v-if="updateForm4"
            class="card-label font-weight-bolder Main_Blue"
            >Update Gender
          </span>
        </div>
        <!-- create form start -->
        <div class="col-12 bg-white p-4">
          <v-form ref="form" v-if="createForm4" v-model="valid" lazy-validation>
            <v-text-field
              v-model="gender"
              :rules="nameRules"
              label="Gender"
              required
            ></v-text-field>
            <button
              type="button"
              @click="createGender"
              class="custom-add-new-record-button btn_dash_ch mr-2"
            >
              <span
                class="v-btn__content px-4"
                style="font-size: 14px !important"
                >Save</span
              >
            </button>
          </v-form>
          <!-- create form end -->

          <!-- update form start -->
          <v-form ref="form" v-if="updateForm4" v-model="valid" lazy-validation>
            <v-text-field
              v-model="gender"
              :rules="nameRules"
              label="Gender"
              required
            ></v-text-field>
            <button
              type="button"
              @click="updateGender"
              class="custom-add-new-record-button btn_dash_ch mr-2"
            >
              <span
                class="v-btn__content px-4"
                style="font-size: 14px !important"
                >Update</span
              >
            </button>
            <v-btn
              style="padding: 8px !important"
              class="custom-add-new-record-button btn_red__ch"
              @click="cancel"
            >
              Cancel
            </v-btn>
          </v-form>
          <!-- update form end -->
        </div>
      </div>
    </div>
    <!-- Confirm Delete Item -->

    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="380px">
        <v-card>
          <v-card-title>
            <span class="headline"
              >Do you really want to DELETE this item?</span
            >
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="custom-submit-button" text @click="dialog = false"
              >No</v-btn
            >
            <v-btn class="custom-cancel-button" text @click="deleteItem"
              ><b-spinner small type="grow" v-if="deleting"></b-spinner
              >Delete</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import { API_URL } from "@/core/api";
import Swal from "sweetalert2";
import GenderService from "@/MainServices/GenderService.js";

export default {
  data() {
    return {
      loading4: true,
      dialog: false,
      deleteId: null,
      deleting: false,
      success4: false,
      token: `Bearer ${localStorage.getItem("token")}`,
      valid: true,
      createForm4: true,
      updateForm4: false,
      gender: "",
      nameRules: [
        (v) => !!v || "Gender is required",
        (v) =>
          (v && v.length <= 10) || "Gender must be less than 10 characters",
      ],
      headers4: [
        { text: "Gender", value: "gender_name" },
        { text: "Status", value: "status" },
        { text: "Action", value: "action" },
      ],
      data4: [],
    };
  },
  mounted() {
    this.getInititalData();
  },
  methods: {
    getInititalData() {
      GenderService.getGenders()
        .then((res) => {
          this.data4 = res.data;
          this.loading4 = false;
        })
        .catch(() => {
          this.loading4 = false;
        });
    },
    cancel4() {
      this.createForm4 = true;
      this.updateForm4 = false;
      this.gender = "";
    },
    createFormEnable4() {
      this.gender = "";
      this.createForm4 = true;
      this.updateForm4 = false;
    },

    createGender() {
      if (this.gender == "") {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      GenderService.addNewGender({
        gender_name: this.gender,
      })
        .then((res) => {
          console.log("anees", res.data);
          this.loading4 = false;
          this.success4 = true;
          this.gender = "";
          Swal.fire({
            title: "",
            text: "Gender type added",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.getInititalData();
          this.cancel4();
        })
        .catch((error) => {
          this.loading4 = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    updateGender() {
      GenderService.editGender(
        {
          gender_name: this.gender,
        },
        this.gender_id
      )
        .then((res) => {
          this.loading4 = false;
          this.success4 = true;
          this.gender = "";
          this.gender_id = "";
          Swal.fire({
            title: "",
            text: "Gender type updated",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.getInititalData();
          this.cancel4();
        })
        .catch((error) => {
          this.loading4 = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    changeGanderStatus(item) {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .put(`${API_URL}/gender/${item.id}`, {
          gender_name: item.gender_name,
          status: !item.status,
        })
        .then((res) => {
          this.loading4 = false;
          this.success4 = true;
          this.gender = "";
          this.gender_id = "";
          Swal.fire({
            title: "",
            text: "Status updated",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.getInititalData();
          this.cancel4();
        })
        .catch(() => {
          this.loading4 = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    editButtonClick4(item) {
      this.createForm4 = false;
      this.updateForm4 = true;
      this.gender = item.gender_name;
      this.gender_id = item.id;
    },

    deleteButtonClick4(item) {
      this.deleting = true;
      GenderService.deleteGender(item)
        .then((res) => {
          Swal.fire({
            title: "",
            text: "Gender type deleted",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.dialog = false;
          this.deleteId = null;
          this.deleting = false;
          this.getInititalData();
          this.loading4 = false;
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.loading4 = false;
        });
    },

    askUserAboutDelete(item) {
      this.deleteId = item.id;
      this.dialog = true;
    },

    deleteItem() {
      this.deleteButtonClick(this.deleteId);
    },
  },
};
</script>
